module.exports = {
    posts: [
      { 
        id: 'hello-world',        title: 'Hello, World!', 
        image: 'healthnet.png',   color: '#eee',
        date: '05/15/2020',

        summary: 'New website, so here\'s a short foreword.',
      },
    ]
}