export default {
  statement: 'Ipsum isolde or something like that',

  degrees: [
    { title: 'Software Engineering BS', school: 'Rochester Institute of Technology',  date: 'May \'20' },
    { title: 'Computer Science MS',     school: 'Rochester Institute of Technology',  date: 'May \'20 '},
  ],

  prof_experience: [
    { title: 'Technical Co-op @ Northrop Grumman',                                    date: 'Jan - Aug \'17' },
    { title: 'Society of Software Engineers Mentor',                                  date: 'Aug - Dec \'17' },
    { title: 'Software Engineering Co-op @ Curriculum Associates',                    date: 'Jan - Jun \'18' },
    { title: 'Teaching Assistant - Intro to Software Engineering (3x)',               date: 'Aug \'17 - May \'19' },
    { title: 'Web Dev Co-op @ SITO',                                                  date: 'May - Aug \'19' },
    { title: 'Web Developer - Doctor on Demand',                                      date: 'July \'20 - Now' },
    { title: 'Senior Software Engineer - Included Health',                            date: 'July \'21 - Now' },
  ],
  other_experience: [

  ],

  // Red - #a97f7f
  // Blue - #827fa9
  // Purp - #917fa9
  // Green - 

  // skills: [ 
  //   {v: 4, color: '#82a97f', title: 'Front-end Web',           sub: ['HTML/CSS', 'VueJS', 'Angular', 'Material', 'UX/UI'] },
  //   {v: 3, color: '#917fa9', title: 'Back-end Web',            sub: ['Java (Spring, Spark)', 'PHP', 'MySQL'] },
  //   {v: 3, color: '#917fa9', title: 'Graphics Programming',    sub: ['OpenGL/GLSL', 'OSL', 'HLS,L'] },
  //   {v: 4, color: '#82a97f', title: 'Software Design',         sub: ['OO Design', 'Patterns', 'Architecture', 'UML', 'Wireframes', ] },
  //   {v: 4, color: '#82a97f', title: 'Software Process',        sub: ['SDLC', 'Scrum', 'Domain-driven Design', ] },

  //   {v: 4, color: '#82a97f', title: 'Stuff I Know Best',   sub: ['JavaScript', 'Java', 'C/C++', 'Git', 'Lucidchart', 'REST'] },
  //   {v: 3, color: '#917fa9', title: 'Stuff I Know Well',   sub: ['Python', 'C#', 'SVN', 'LaTeX', 'YAML, XML, etc'] },
  //   {v: 2, color: '#a97f7f', title: 'Stuff I\'ve Used',    sub: ['Ruby', 'PHP', 'SQL'] },
  // ],

  skills: [ 
    {v: 4, color: '#82a97f', title: 'Front-end Web',           sub: ['Vue, React', 'Angular (1.x, 7/8)', 'Architecture', 'State Mgmt (VueX)', 'Persistence'] },
    {v: 3, color: '#917fa9', title: 'Back-end Web',            sub: ['REST, GraphQL', 'Python (Django)', 'Java (Spring)', 'MySQL', 'PHP', 'Ruby'] },
    {v: 2, color: '#917fa9', title: 'CI/CD Monitoring',        sub: ['Docker', 'CircleCI/Jenkins', 'Rollbar/Splunk', 'GitHub'] },
    {v: 4, color: '#82a97f', title: 'Software Design',         sub: ['OOD, Patterns', 'Architecture', 'UML', 'Wireframes', 'UX/UI' ] },
    {v: 4, color: '#82a97f', title: 'Process',                 sub: ['SDLC', 'Scrum', 'Domain-driven Design', 'Interviewing', ] }, 
    
    {v: 4, color: '#82a97f', title: 'Stuff I Know Best',       sub: ['JavaScript', 'Java', 'C/C++', 'Git', 'Lucidchart', 'REST'] },
    {v: 3, color: '#917fa9', title: 'Stuff I\'ve Used',        sub: ['Python', 'C#', 'LaTeX', 'YAML, XML, etc'] },
    {v: 2, color: '#a97f7f', title: 'Want More Experience',    sub: ['Fullstack', 'Databases', 'CI/CD Setup'] },
  ],

  links: [
    { title: 'GitHub',    icon: 'icons/square-github.svg', link: 'https://github.com/rmnill202/'},
    { title: 'Twitter',   icon: 'icons/square-twitter.svg', link: 'https://twitter.com/rmnill202'},
    { title: 'LinkedIn',  icon: 'icons/linkedin.svg', link: 'https://www.linkedin.com/in/robert-nill-096b64112/'},
    { title: 'Facebook',  icon: 'icons/square-facebook.svg', link: 'https://www.facebook.com/robert.m.nill'}
  ],

  contacts: [
    { title: 'rmnill202mail@gmail.com', link: 'mailto:rmnill202mail@gmail.com'},
    { title: 'LinkedIn', link: 'https://www.linkedin.com/in/robert-nill-096b64112/'},
  ]
};